import React, { useState, useEffect } from "react";
import Layout from "./../components/Layout/Layout";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { IoBackspaceOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { useCart } from '../context/cart'
import toast from 'react-hot-toast'



const ProductDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [product, setProduct] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);

    const [cart, setCart] = useCart();


    //initial product details
    useEffect(() => {
        if (params?.slug) getProduct();
    }, [params?.slug]);

    //getProduct
    const getProduct = async () => {
        try {
            const { data } = await axios.get(
               ` /api/v1/product/get-product/${params.slug}`
            );
            setProduct(data?.product);
            getSimilarProduct(data?.product._id, data?.product.category._id);
        } catch (error) {
            console.log(error);
        }
    };

    //get similar product
    const getSimilarProduct = async (pid, cid) => {
        try {
            const { data } = await axios.get(`/api/v1/product/related-product/${pid}/${cid}`);
            setRelatedProducts(data?.products);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Layout>
            <div className="container px-2  ">
                <NavLink to="/" className="list-group-item list-group-item-action pt-3">
                    <IoBackspaceOutline size={30} />
                </NavLink>
                <h1 className="text-center">Product Details</h1>
                <div className="container p-3">
                    <div className="row">
                        {/* Product Image */}
                        <div className="col-md-4 col-sm-12 d-flex justify-content-center pb-3">
                            <img
                                src={`/api/v1/product/product-photo/${product._id}`}
                                className="img-fluid"
                                alt={product.name}
                                style={{ maxWidth: "250px", height: "auto", width: "100%" }} // Limits max width
                            />
                        </div>

                        {/* Product Details */}
                        <div className="col-md-8 col-sm-12 pt-3">
                            <h1>{product.name}</h1>
                            <h6>Description: {product.description}</h6>
                            <h6>Price: ₹{product.price}</h6>
                            {/* <h6>Category: {product?.category?.name}</h6> */}
                            <button
                                className="btn btn-secondary ms-2 mb-2"
                                onClick={() => {
                                    setCart([...cart, product]);
                                    localStorage.setItem("cart", JSON.stringify([...cart, product]));
                                    toast.success("Item Added to cart");
                                }}
                            >
                                ADD TO CART
                            </button>
                        </div>
                    </div>
                </div>
                {/* <hr /> */}
                {/* Similar Products Section */}
                {/* <div className="row">
                    <h6 className="mb-3">Similar Products</h6>
                    {relatedProducts.length < 1 ? (
                        <p className="text-center">No Similar Products found</p>
                    ) : (
                        <div className="row">
                            {relatedProducts?.map((p) => (
                                <div key={p._id} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                    <div className="card h-100">
                                        <img
                                            src={/api/v1/product/product-photo/${p?._id}}
                                            className="card-img-top"
                                            alt={p.name}
                                        />
                                        <div className="card-body">
                                            <h5 className="card-title">{p.name}</h5>
                                            <p className="card-text">
                                                {p.description.substring(0, 30)}...
                                            </p>
                                            <p className="card-text">Price: ${p.price}</p>
                                            <button
                                                className="btn btn-primary ms-2 mb-2  w-md-auto"
                                                onClick={() => navigate(/product/${p.slug})}
                                            >
                                                More Details
                                            </button>
                                            <button className="btn btn-secondary ms-2 mb-2  w-md-auto">
                                                ADD TO CART
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div> */}
            </div>
        </Layout>
    );
};

export default ProductDetails;