import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import AdminMenu from '../../components/Layout/AdminMenu';
import axios from 'axios'; // You'll use Axios to make API requests
import { useAuth } from "../../context/auth";

const Users = () => {
    const [auth, setAuth] = useAuth();
    const [users, setUsers] = useState([]); // State to store fetched users

    // Function to fetch users
    const fetchUsers = async () => {
        try {
            const { data } = await axios.get('/api/v1/auth/users'); // Make API call to get users
            if (data.success) {
                setUsers(data.users); // Set the fetched users in state
            } else {
                // console.log('Failed to fetch users');
            }
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    // useEffect to fetch users on component mount
    useEffect(() => {
        fetchUsers();
    }, []);



    return (
        <Layout
            title={"Admin Dashboard - Shivaanzarionline | Manage Users"}
            description={"Access the Admin Dashboard at Shivaanzarionline to manage users, create and update products, and view detailed order information. Efficiently oversee and control all aspects of the online store."}
            keyword={"Shivaanzarionline, admin dashboard, manage users, create products, update products, delete products, order details, e-commerce management, online store administration"}
            author={"Shivaanzarionline Team"}
        >
            <div className="container-fluid p-3">
                <div className="row m-3">
                    {/* Sidebar */}
                    <div className="col-12 col-md-3 mb-3">
                        <AdminMenu />
                    </div>
                    {/* Main content */}
                    <div className="col-12 col-md-9">
                        <h1 className="mb-4">User Details</h1>

                        {/* Table */}
                        <div className="table-responsive">
  <table className="table table-bordered table-hover">
    <thead className="table-light">
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Address</th>
        <th>UPI ID</th> {/* New UPI ID Column */}
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr key={user._id}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.phone}</td>
          <td>{user.address}</td>
          <td>{user.upi_id ? user.upi_id : "N/A"}</td> {/* Display UPI ID */}
        </tr>
      ))}
    </tbody>
  </table>
</div>

                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default Users;
