export const Prices = [
    {
      _id: 0,
      name: "₹50 to 250",
      array: [0, 250],
    },
    {
      _id: 1,
      name: "₹250 to 500",
      array: [251, 500],
    },
    {
      _id: 2,
      name: "₹500 to 800",
      array: [501, 800],
    },
    {
      _id: 3,
      name: "₹800 to 1000",
      array: [801, 1000],
    },
    {
      _id: 4,
      name: "₹1000 to 1500",
      array: [1001, 1500],
    },
    {
      _id: 4,
      name: "₹1500 or more",
      array: [1501, 999999],
    },
  ];