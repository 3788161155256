import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useAuth } from '../../context/auth';
import toast from 'react-hot-toast';
import SearchInput from '../Form/SerachInput';
// 
// import useCategory from '../../hooks/useCategory';
import { useCart } from '../../context/cart';
import { Badge } from "antd";


const Header = () => {
    const [auth, setAuth] = useAuth();
    const [cart] = useCart();

    // 
    // const categories = useCategory();

    const handleLogout = () => {
        setAuth({
            ...auth, user: null, token: ""
        })
        // alert("Do you want to log out?");
        localStorage.removeItem('auth');
        // toast.success("Logout Successfully")
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand heading_font d-flex align-items-center">
                        <img
                            src="/images/logo_Shivaanzarionline.png"
                            alt="logo"
                            style={{
                                width: "50px", // Set a specific width for the logo
                                height: "auto", // Maintain aspect ratio
                                borderRadius: "10px", // Optional, for a rounded logo
                            }}
                            className="me-2" // Margin end for spacing
                        />
                        Shivaanzarionline
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarTogglerDemo01"
                        aria-controls="navbarTogglerDemo01"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
                            <div className="mx-3 py-2">
                                <SearchInput />
                            </div>
                            <li className="nav-item mx-2">
                                <NavLink to="/" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                                    Home
                                </NavLink>
                            </li>

                            {/*  */}
                            {/* <li className="nav-item dropdown">
                                <Link
                                    className="nav-link dropdown-toggle"
                                    to={"/categories"}
                                    data-bs-toggle="dropdown"
                                >
                                    Categories
                                </Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link className="dropdown-item" to={"/categories"}>
                                            All Categories
                                        </Link>
                                    </li>
                                    {categories?.map((c) => (
                                        <li>
                                            <Link
                                                className="dropdown-item"
                                                to={`/category/${c.slug}`}
                                            >
                                                {c.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li> */}



                            {!auth.user ? (
                                <>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/register" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                                            Register
                                        </NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/login" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                                            Login
                                        </NavLink>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li className="nav-item dropdown mx-2">
                                        <NavLink className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {auth?.user?.name}
                                        </NavLink>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li>
                                                <NavLink to={`/dashboard/${auth?.user?.role === 1 ? "admin" : "user"}`} className="dropdown-item">
                                                    Dashboard
                                                </NavLink>
                                            </li>
                                            <li>
                                                <NavLink onClick={handleLogout} to="/login" className="dropdown-item">
                                                    Logout
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li>
                                </>
                            )}
                            <li className="nav-item mx-2 py-3">
                                <Badge count={cart?.length} showZero>
                                    <NavLink to="/cart" className={({ isActive }) => isActive ? "nav-link active position-relative" : "nav-link position-relative"}>
                                        Cart
                                        {/* <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle"> {cart?.length}</span> */}
                                    </NavLink>
                                </Badge>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>

        </>
    )
}

export default Header
