import React from 'react'
import Layout from '../../components/Layout/Layout'
import UserMenu from '../../components/Layout/UserMenu'
import { useAuth } from '../../context/auth'

const Dashboard = () => {

  const [auth] = useAuth()

  return (
    <Layout
      title={"Dashboard - Shivaanzarionline | Manage Your Account"}
      description={"Access and manage your Shivaanzarionline account from the User Dashboard. View and update your profile, track your orders, and review your purchase history all in one place."}
      keyword={"Shivaanzarionline, user dashboard, manage account, view orders, update profile, account settings, purchase history, online shopping, user profile"}
      author={"Shivaanzarionline Team"}
    >
      <div className="container-fluid user-profile-container p-3">
        <div className="row user-profile-row m-3">
          <div className="col-md-3 user-menu-col mb-3">
            <UserMenu />
          </div>
          <div className="col-md-9 user-details-col">
            <div className="card user-details-card w-100 p-4 shadow-lg">
              <h3 className="user-info user-name mb-3">Name: {auth?.user?.name}</h3>
              <h3 className="user-info user-email mb-3">Email: {auth?.user?.email}</h3>
              <h3 className="user-info user-address mb-3">Address: {auth?.user?.address}</h3>
              <h3 className="user-info user-phone mb-3">Phone: {auth?.user?.phone}</h3>
            </div>
          </div>
        </div>
      </div>


    </Layout>
  )
}
export default Dashboard
