import React from 'react'
import Layout from '../components/Layout/Layout'
import { Link } from 'react-router-dom'

const Pagenotfound = () => {
    return (
        <Layout 
        title={"Page Not Found - Shivaanzarionline | Error 404"}
        description={"Oops! The page you're looking for cannot be found. Visit Shivaanzarionline's homepage or use the search function to find what you need."}
        keyword={"Shivaanzarionline, page not found, error 404, broken link, website error, online shopping"}
        author={"Shivaanzarionline Team"}
        >
            <div className="container d-flex flex-column align-items-center justify-content-center min-vh-65">
                <h1 className="display-1 font-weight-bold">404</h1>
                <h2 className="font-weight-normal">Oops! Page Not Found</h2>
                <Link to="/" className="btn btn-outline-dark mt-3">
                    Go Back
                </Link>
            </div>

        </Layout>
    )
}

export default Pagenotfound
