import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import axios from 'axios'
import { Checkbox, Radio } from 'antd'
import { Prices } from '../components/Prices'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../context/cart'
import toast from 'react-hot-toast'


const HomePage = () => {

  const navigate = useNavigate();
  const [cart, setCart] = useCart();

  // 
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0); // track total pages



  // get all category
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get('/api/v1/category/get-category');
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCategory();
    getTotal();
  }, []);

  //get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [])

  //getTotal Count
  const getTotal = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/product-count");
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   if (page === 1) return;
  //   loadMore();
  // }, [page]);

  // load more
  // Fetch products when the page changes
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      setLoading(false);
      setProducts(data?.products);
      setTotalPages(data?.totalPages); // store total pages
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Fetch products on initial load and when the page changes
  useEffect(() => {
    fetchProducts();
  }, [page]);

  // Function to render pagination
  const renderPagination = () => {
    let pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`btn ${page === i ? 'btn-primary' : 'btn-secondary'} mx-1`}
          onClick={() => setPage(i)} // update the current page
        >
          {i}
        </button>
      );
    }
    return pages;
  };


  // filter by cat
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };
  useEffect(() => {
    if (!checked.length || !radio.length) getAllProducts();
  }, [checked.length, radio.length]);

  useEffect(() => {
    if (checked.length || radio.length) filterProduct();
  }, [checked, radio]);

  //get filterd product
  const filterProduct = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/product-filters", {
        checked,
        radio,
      });
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };
  // 






  return (
    <Layout
      title={"Shivaanzarionline - Premium Products at Affordable Prices"}
      description={"Discover a wide range of premium home essentials at Shivaanzarionline. From kitchenware to furniture, we offer quality products at unbeatable prices with fast shipping."}
      keyword={"home essentials, kitchenware, furniture, home decor, affordable home products, Shivaanzarionline, online shopping, quality home goods, new products, easy to stored, cheap price"}
      author={"Shivaanzarionline Team"}
    >
      {/* banner */}
      <div className="pb-3 px-0">
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src="./images/banner_2.png" className="d-block w-100 img-fluid" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="./images/banner_1.png" className="d-block w-100 img-fluid" alt="..." />
            </div>
            <div className="carousel-item">
              <img src="./images/banner_3.png" className="d-block w-100 img-fluid" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3">
        <h1 className="text-center heading_font">Products For You</h1>
        <div className="row">
          <div className="col-md-3 p-2">
            {/* Category filter */}
            <div className="accordion " id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed p-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Filter
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    {/* <h5 className="text-center">Filter By Category</h5> */}
                    <div className="d-flex flex-column">
                      {/* {categories?.map((c) => (
                        <Checkbox
                          key={c._id}
                          onChange={(e) => handleFilter(e.target.checked, c._id)}
                        >
                          {c.name}
                        </Checkbox>
                      ))} */}

                      {/* Price filter */}
                      <h5 className="text-center">Filter By Price</h5>
                      <div className="d-flex flex-column">
                        <Radio.Group onChange={(e) => setRadio(e.target.value)}>
                          {Prices?.map((p, index) => (
                            <div key={`${p._id}-${index}`}>
                              <Radio value={p.array}>{p.name}</Radio>
                            </div>
                          ))}
                        </Radio.Group>
                      </div>

                      {/* Reset button */}
                      <div className="d-flex flex-column my-2">
                        <button className="btn btn-danger" onClick={() => window.location.reload()}>
                          RESET FILTERS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-9 p-2">
            <div className="d-flex flex-wrap">
              {products?.map((p, index) => (
                <div key={`${p._id}-${index}`} className="col-12 col-sm-6 col-lg-4 mb-3">
                  <div className="card d-flex flex-column h-100 mx-2">
                    <img
                      src={`/api/v1/product/product-photo/${p._id}`}
                      className="card-img-top img-fluid p-2"
                      alt={p.name}
                      style={{ height: "220px" }}
                    />
                    <div className="card-body d-flex flex-column">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title mb-0">{p.name}</h5>
                        <h5 className="card-text mb-0 text-success">
                          {p.price.toLocaleString("en-IN", {
                            style: "currency",
                            currency: "INR",
                          })}
                        </h5>
                      </div>

                      <p className="card-text mb-auto py-2">{p.description.substring(0, 30)}...</p>
                      <div className="mt-auto d-flex justify-content-between flex-wrap">
                        <button
                          className="btn btn-primary ms-2 mb-2"
                          onClick={() => navigate(`/product/${p.slug}`)}
                        >
                          More details
                        </button>
                        <button
                          className="btn btn-secondary ms-2 mb-2"
                          onClick={() => {
                            const { _id, name, description, price } = p; // Only store essential fields
                            const updatedCart = [...cart, { _id, name, description, price }]; // Add product to the cart with essential details

                            try {
                              // Save the updated cart to localStorage
                              localStorage.setItem("cart", JSON.stringify(updatedCart));
                              setCart(updatedCart); // Update the cart state
                              toast.success("Item Added to cart");
                            } catch (error) {
                              console.error("Error adding item to cart:", error);
                              toast.error("Failed to add item to cart");
                            }
                          }}
                        >
                          ADD TO CART
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center flex-wrap my-3">
                  {/* Previous button */}
                  {page > 1 && (
                    <button
                      className="btn btn-secondary mx-1"
                      onClick={() => setPage(page - 1)} // Decrement page on click
                    >
                      Previous
                    </button>
                  )}

                  {/* Pagination */}
                  <div className="pagination d-flex flex-wrap">
                    {renderPagination()}
                  </div>

                  {/* Next button */}
                  {page < totalPages && (
                    <button
                      className="btn btn-secondary mx-1"
                      onClick={() => setPage(page + 1)} // Increment page on click
                    >
                      Next
                    </button>
                  )}
                </div>

                {/* Loading state */}
                {loading && (
                  <div className="col-12 text-center">
                    <p>Loading...</p>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>

  )
}

export default HomePage
