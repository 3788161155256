import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout/Layout";
import AdminMenu from "./../../components/Layout/AdminMenu";
import toast from "react-hot-toast";
import axios from "axios";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
const { Option } = Select;

const UpdateProduct = () => {
    const navigate = useNavigate();
    const params = useParams();
    // const [categories, setCategories] = useState([]);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    // const [category, setCategory] = useState("");
    const [quantity, setQuantity] = useState("");
    const [shipping, setShipping] = useState("");
    const [photo, setPhoto] = useState("");
    const [id, setId] = useState("");

    //get single product
    const getSingleProduct = async () => {
        try {
            const { data } = await axios.get(
               ` /api/v1/product/get-product/${params.slug}`
            );
            setName(data.product.name);
            setId(data.product._id);
            setDescription(data.product.description);
            setPrice(data.product.price);
            setPrice(data.product.price);
            setQuantity(data.product.quantity);
            setShipping(data.product.shipping);
            // setCategory(data.product.category._id);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        getSingleProduct();
        //eslint-disable-next-line
    }, []);
    //get all category
    // const getAllCategory = async () => {
    //     try {
    //         const { data } = await axios.get("/api/v1/category/get-category");
    //         if (data?.success) {
    //             setCategories(data?.category);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //         toast.error("Something wwent wrong in getting catgeory");
    //     }
    // };

    // useEffect(() => {
    //     getAllCategory();
    // }, []);

    //create product function
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const productData = new FormData();
            productData.append("name", name);
            productData.append("description", description);
            productData.append("price", price);
            productData.append("quantity", quantity);
            photo && productData.append("photo", photo);
            // productData.append("category", category);
            const { data } = axios.put(`/api/v1/product/update-product/${id}`,
                productData
            );
            if (data?.success) {
                toast.error(data?.message);
            } else {
                toast.success("Product Updated Successfully");
                navigate("/dashboard/admin/products");
            }
        } catch (error) {
            console.log(error);
            toast.error("something went wrong");
        }
    };

    //delete a product
    const handleDelete = async () => {
        try {
            let answer = window.prompt("Are You Sure want to delete this product ? ");
            if (!answer) return;
            const { data } = await axios.delete(`/api/v1/product/product-delete/${id}`);
            toast.success("Product DEleted Succfully");
            navigate("/dashboard/admin/products");
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
        }
    };
    return (
        <Layout
            title={"Admin Dashboard - Shivaanzarionline | Update Products"}
            description={"Access the Admin Dashboard at Shivaanzarionline to manage users, create and update products, and view detailed order information. Efficiently oversee and control all aspects of the online store."}
            keyword={"Shivaanzarionline, admin dashboard, manage users, create products, update products, delete products, order details, e-commerce management, online store administration"}
            author={"Shivaanzarionline Team"}
        >
            <div className="container-fluid p-3">
                <div className="row">
                    {/* Sidebar */}
                    <div className="col-12 col-md-3 mb-3">
                        <AdminMenu />
                    </div>

                    {/* Main Content */}
                    <div className="col-12 col-md-9">
                        <h1 className="text-center">Update Product</h1>

                        {/* Form Section */}
                        <div className="m-1 w-100 w-md-75 mx-auto">
                            {/* Category Select
                            <Select
                                variant={false}
                                placeholder="Select a category"
                                size="large"
                                showSearch
                                className="form-select mb-3"
                                onChange={(value) => setCategory(value)}
                                value={category}
                            >
                                {categories?.map((c) => (
                                    <Option key={c._id} value={c._id}>
                                        {c.name}
                                    </Option>
                                ))}
                            </Select> */}

                            {/* Photo Upload */}
                            <div className="mb-3">
                                <label className="btn btn-outline-secondary w-100">
                                    {photo ? photo.name : "Upload Photo"}
                                    <input
                                        type="file"
                                        name="photo"
                                        accept="image/*"
                                        onChange={(e) => setPhoto(e.target.files[0])}
                                        hidden
                                    />
                                </label>
                            </div>

                            {/* Display Photo */}
                            <div className="mb-3 text-center">
                                {photo ? (
                                    <img
                                        src={URL.createObjectURL(photo)}
                                        alt="product_photo"
                                        height={"200px"}
                                        className="img img-responsive"
                                    />
                                ) : (
                                    <img
                                        src={`/api/v1/product/product-photo/${id}`}
                                        alt="product_photo"
                                        height={"200px"}
                                        className="img img-responsive"
                                    />
                                )}
                            </div>

                            {/* Name Input */}
                            <div className="mb-3">
                                <input
                                    type="text"
                                    value={name}
                                    placeholder="Write a name"
                                    className="form-control"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            {/* Description Input */}
                            <div className="mb-3">
                                <textarea
                                    type="text"
                                    value={description}
                                    placeholder="Write a description"
                                    className="form-control"
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>

                            {/* Price Input */}
                            <div className="mb-3">
                                <input
                                    type="number"
                                    value={price}
                                    placeholder="Write a price"
                                    className="form-control"
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>

                            {/* Quantity Input */}
                            <div className="mb-3">
                                <input
                                    type="number"
                                    value={quantity}
                                    placeholder="Write a quantity"
                                    className="form-control"
                                    onChange={(e) => setQuantity(e.target.value)}
                                />
                            </div>

                            {/* Shipping Select */}
                            <div className="mb-3">
                                <Select
                                    variant={false}
                                    placeholder="Select Shipping"
                                    size="large"
                                    showSearch
                                    className="form-select mb-3"
                                    onChange={(value) => setShipping(value)}
                                    value={shipping ? "Yes" : "No"}
                                >
                                    <Option value="0">No</Option>
                                    <Option value="1">Yes</Option>
                                </Select>
                            </div>

                            {/* Update and Delete Buttons */}
                            <div className="mb-3 d-flex justify-content-between">
                                <button className="btn btn-primary mx-3" onClick={handleUpdate}>
                                    UPDATE PRODUCT
                                </button>
                                <button className="btn btn-danger" onClick={handleDelete}>
                                    DELETE PRODUCT
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default UpdateProduct;