import React from 'react'
import Layout from '../components/Layout/Layout'

const Policy = () => {
    return (
        <Layout
            title={"Privacy Policy - Shivaanzarionline | Your Data Security Matters"}
            description={"Shivaanzarionline, privacy policy, data security, personal information, user privacy, online shopping, data protection"}
            keyword={"Shivaanzarionline, privacy policy, data security, personal information, user privacy, online shopping, data protection"}
            author={"Shivaanzarionline Team"}
        >
            <div className="container py-4">
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <h2 className="text-center heading_font mb-3">Privacy Policy</h2>
                        <p className="text-justify">
                            Welcome to <strong> Shivaanzarionline</strong>. This Privacy Policy explains how we collect, use, and protect your personal information when you visit or make a purchase from our website.
                        </p>
                        <p className="text-justify">
                            <strong>1. Information We Collect:</strong> We collect personal information that you provide to us directly, such as when you create an account, place an order, or subscribe to our newsletter. This may include your name, email address, phone number, billing and shipping address, and payment information.
                        </p>
                        <p className="text-justify">
                            <strong>2. How We Use Your Information:</strong> We use the information we collect to process your orders, communicate with you, improve our website, and provide customer support. We may also use your information to send you promotional materials or offers, but you can opt out of these communications at any time.
                        </p>
                        <p className="text-justify">
                            <strong>3. Sharing Your Information:</strong> We do not sell or rent your personal information to third parties. However, we may share your information with trusted service providers who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.
                        </p>
                        <p className="text-justify">
                            <strong>4. Data Security:</strong> We take the security of your personal information seriously and implement appropriate technical and organizational measures to protect it. However, no method of transmission over the internet, or method of electronic storage, is 100% secure, and we cannot guarantee its absolute security.
                        </p>
                        <p className="text-justify">
                            <strong>5. Cookies:</strong> We use cookies to enhance your browsing experience, analyze site traffic, and personalize content. You can choose to accept or decline cookies through your browser settings. However, disabling cookies may affect the functionality of our website.
                        </p>
                        <p className="text-justify">
                            <strong>6. Your Rights:</strong> You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights, please contact us using the information provided below.
                        </p>
                        <p className="text-justify">
                            <strong>7. Changes to This Privacy Policy:</strong> We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date at the top will be revised. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                        </p>
                        <p className="text-justify">
                            <strong>8. Contact Us:</strong> If you have any questions or concerns about this Privacy Policy, please contact us at:
                        </p>
                        <ul>
                            <li>Email: shivaanzarionline@gmail.com</li>
                            <li>Phone: +91 9372303779</li>
                            <li>Address: 36/37-A, Abhyankar Nagar, Nagpur - 440010, Maharashtra, India</li>
                        </ul>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default Policy
