import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { Helmet } from "react-helmet";
import  { Toaster } from 'react-hot-toast';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


const Layout = ({ children, title, description, keyword, author }) => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keyword} />
                <meta name="author" content={author} />
            </Helmet>
            <Header />
            <main className='body_bg' style={{ minHeight: "70vh" }}>
                <Toaster />
                {/* <ToastContainer /> */}
                {children}
            </main>
            <Footer />
        </div>
    )

}

// Layout.defaultProps = {
//     title: 'Shivaanzarionline - Shop now',
//     description: 'IT is online  shipoing website for',
//     keyword: 'Shop, online, sales, money',
//     author: 'Amit Despande',
// };


export default Layout
