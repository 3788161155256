import React from 'react'
import Layout from '../../components/Layout/Layout'
import AdminMenu from '../../components/Layout/AdminMenu'
import { useAuth } from '../../context/auth'

const AdminDashboard = () => {

    const [auth] = useAuth()


    return (
        <Layout
            title={"Admin Dashboard - Shivaanzarionline | Manage Users, Products, and Orders"}
            description={"Access the Admin Dashboard at Shivaanzarionline to manage users, create and update products, and view detailed order information. Efficiently oversee and control all aspects of the online store."}
            keyword={"Shivaanzarionline, admin dashboard, manage users, create products, update products, delete products, order details, e-commerce management, online store administration"}
            author={"Shivaanzarionline Team"}
        >
            <div className="container-fluid user-profile-container p-3">
                <div className="row user-profile-row m-3">
                    <div className="col-md-3 user-menu-col mb-3">
                        <AdminMenu />
                    </div>
                    <div className="col-md-9 user-details-col">
                        <div className="card user-details-card w-100 p-4 shadow-lg">
                            <h3 className="user-info user-name mb-3">Admin Name: {auth?.user?.name}</h3>
                            <h3 className="user-info user-email mb-3">Admin Email: {auth?.user?.email}</h3>
                            <h3 className="user-info user-phone mb-3">Admin Contact: {auth?.user?.phone}</h3>
                        </div>
                    </div>

                </div>
            </div>

        </Layout>
    )
}

export default AdminDashboard
