import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "../../components/Layout/Layout";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { Select } from "antd";
const { Option } = Select;

const AdminOrders = () => {



    const [status, setStatus] = useState([
        "Not Process",
        "Processing",
        "Shipped",
        "deliverd",
        "cancel",
    ]);
    // const [changeStatus, setCHangeStatus] = useState("");
    const [orders, setOrders] = useState([]);
    const [auth, setAuth] = useAuth();
    const getOrders = async () => {
        try {
            const { data } = await axios.get("/api/v1/auth/all-orders");
            //   console.log(data);  // Log the data to ensure the orders are returned
            if (data.success) {
                setOrders(data.orders);
            } else {
                toast.error("Failed to fetch orders");
            }
        } catch (error) {
            console.log(error);
            toast.error("Error fetching orders");
        }
    };

    useEffect(() => {
        if (auth?.token) getOrders();
    }, [auth?.token]);

    const handleChange = async (orderId, value) => {
        try {
            const { data } = await axios.put(`/api/v1/auth/order-status/${orderId}`, {
                status: value,
            });
            getOrders();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Layout
            title={"Admin Dashboard - Shivaanzarionline | Manage Orders"}
            description={"Access the Admin Dashboard at Shivaanzarionline to manage users, create and update products, and view detailed order information. Efficiently oversee and control all aspects of the online store."}
            keyword={"Shivaanzarionline, admin dashboard, manage users, create products, update products, delete products, order details, e-commerce management, online store administration"}
            author={"Shivaanzarionline Team"}
        >
            <div className="container-fluid dashboard pt-2">
                <div className="row">
                    <div className="col-md-3">
                        <AdminMenu />
                    </div>
                    <div className="col-md-9">
                        <h2 className="text-center pt-2">All Orders</h2>
                        {orders?.map((o, i) => (
                            <div className="border shadow mb-3" key={o._id}>
                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Buyer</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Payment</th>
                                                <th scope="col">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>
                                                <td>{i + 1}</td>
                                                {/* <td>{o?.status}</td> */}
                                                <td>
                                                    <Select
                                                        variant={false}
                                                        onChange={(value) => handleChange(o._id, value)}
                                                        defaultValue={o?.status}
                                                    >
                                                        {status.map((s, i) => (
                                                            <Option key={i} value={s}>
                                                                {s}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </td>
                                                <td>{o?.buyer?.name}</td>
                                                <td>{moment(o?.createdAt).fromNow()}</td>
                                                <td>{o?.payment?.success ? "Success" : "Failed"}</td>
                                                <td>{o?.products?.length}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                               Product Details
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div className="container">
                                                    {o?.products?.map((p) => (
                                                        <div className="row mb-2 p-3 card flex-md-row" key={p._id}>
                                                            <div className="col-4 col-sm-3 col-md-4">
                                                                <img
                                                                    src={`/api/v1/product/product-photo/${p._id}`}
                                                                    className="img-thumbnail w-75 card-img-top"
                                                                    alt={p.name}
                                                                />
                                                            </div>
                                                            <div className="col-12 col-md-8">
                                                                <h5>{p.name}</h5>
                                                                <p>{p.description}</p>
                                                                <h6 className="text-success">{p.price.toLocaleString("en-IN", {
                                                                    style: "currency",
                                                                    currency: "INR",
                                                                })}</h6>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </Layout>
    );
};

export default AdminOrders;