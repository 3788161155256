import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { FaUser, FaPhone, FaAddressCard } from "react-icons/fa";
import { MdEmail, MdOutlineSecurity } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import toast from 'react-hot-toast';
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'

const Register = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [answer, setAnswer] = useState("");

    const navigate = useNavigate();

    // Form function 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Fetch the infomation into mangodb database
            const res = await axios.post("/api/v1/auth/register", {
                name,
                email,
                password,
                phone,
                address,
                answer
            });
            if (res && res.data.success) {
                toast.success(res.data && res.data.message);
                setTimeout(() => {
                    navigate("/login");
                  }, 2000);
            }
            else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }
    };

    return (
        <Layout 
        title={"Register - Shivaanzarionline | Create Your Account Today"}
        description={"Register on Shivaanzarionline to access premium home essentials, exclusive offers, and a seamless shopping experience. Sign up today and enjoy fast delivery and secure checkout."}
        keyword={"Shivaanzarionline, register, create account, home essentials, online shopping, sign up, exclusive offers, fast delivery, secure checkout"}
        author={"Shivaanzarionline"}
        >
            <div className="container-fluid py-5 bg-light">
                <div className="row justify-content-center align-items-center mx-1">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 shadow-lg p-4 border border-primary rounded my-4 bg-white">
                        <h1 className="text-center mb-4 text-primary heading_font">Register</h1>
                        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                            {/* Name Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <FaUser />
                                </span>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="form-control"
                                    placeholder="Full Name"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your name.
                                </div>
                            </div>
                            {/* Email Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <MdEmail />
                                </span>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    placeholder="Email"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter a valid email.
                                </div>
                            </div>
                            {/* Password Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <RiLockPasswordFill />
                                </span>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="form-control"
                                    placeholder="Password"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your password.
                                </div>
                            </div>
                            {/* Phone Number Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <FaPhone />
                                </span>
                                <input
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    className="form-control"
                                    placeholder="Phone Number"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your phone number.
                                </div>
                            </div>
                            {/* Address Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    < FaAddressCard />
                                </span>
                                <input
                                    type="text"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                    className="form-control"
                                    placeholder="Address"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your address.
                                </div>
                            </div>
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    < MdOutlineSecurity />
                                </span>
                                <input
                                    type="text"
                                    value={answer}
                                    onChange={(e) => setAnswer(e.target.value)}
                                    className="form-control"
                                    placeholder="What is the name of the town where you were born ?"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your answer.
                                </div>
                            </div>
                            {/* Submit Button */}
                            <button type="submit" className="btn btn-primary w-100 btn-lg">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Register
