import React, { useState, useEffect } from "react";
import AdminMenu from '../../components/Layout/AdminMenu'
import Layout from '../../components/Layout/Layout'
import axios from "axios";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const Products = () => {

    const [products, setProducts] = useState([]);

    //getall products
    const getAllProducts = async () => {
        try {
            const { data } = await axios.get("/api/v1/product/get-product");
            if (data.success) {
                setProducts(data.products);
            } else {
                toast.error("Failed to load products");
            }
        } catch (error) {
            console.log(error);
            toast.error("Something Went Wrong");
        }
    };



    //lifecycle method
    useEffect(() => {
        getAllProducts();
    }, []);

    return (
        <Layout
            title={"Admin Dashboard - Shivaanzarionline | Manage Products"}
            description={"Access the Admin Dashboard at Shivaanzarionline to manage users, create and update products, and view detailed order information. Efficiently oversee and control all aspects of the online store."}
            keyword={"Shivaanzarionline, admin dashboard, manage users, create products, update products, delete products, order details, e-commerce management, online store administration"}
            author={"Shivaanzarionline Team"}
        >
            <div className="container-fluid p-3">
                <div className="row">
                    {/* Sidebar */}
                    <div className="col-12 col-md-3 mb-3">
                        <AdminMenu />
                    </div>

                    {/* Main Content */}
                    <div className="col-12 col-md-9">
                        <h1 className="text-center">All Product List</h1>

                        {/* Product Cards */}
                        <div className="row g-3">
                            {products?.map((p) => (
                                <div key={p._id} className="col-12 col-sm-6 col-md-4 col-lg-4">
                                    <Link
                                        key={p._id}
                                        to={`/dashboard/admin/product/${p.slug}`}
                                        className="product-link"
                                    >
                                        <div className="card d-flex flex-column h-100 mx-2">
                                            <img
                                                src={`/api/v1/product/product-photo/${p._id}`}
                                                className="card-img-top img-fluid"
                                                alt={p.name}
                                                style={{ height: "220px" }} // Responsive image
                                            />
                                            <div className="card-body d-flex flex-column">
                                                <h5 className="card-title">{p.name}</h5>
                                                <p className="card-text">{p.description.substring(0, 30)}...</p>
                                                <h5 className="card-text text-success">
                                                    Price - {p.price.toLocaleString("en-IN", {
                                                        style: "currency",
                                                        currency: "INR",
                                                    })}
                                                </h5>
                                                <h5 className="card-text">Quantity - {p.quantity}</h5>

                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default Products
