import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { MdEmail, MdOutlineSecurity } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'



const ForgotPassword = () => {

    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [answer, setAnswer] = useState("");

    const navigate = useNavigate();

    // Form function 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Fetch the infomation into mangodb database
            const res = await axios.post("/api/v1/auth/forgot-password", {
                email,
                answer,
                newPassword
            });
            if (res && res.data.success) {
                toast.success(res.data && res.data.message);
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            }
            else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }
    };

    return (
        <Layout 
        title={"Forgot Password - Shivaanzarionline | Reset Your Account Password"}
        description={"Reset your Shivaanzarionline account password quickly and securely. Enter your email address to receive instructions on how to regain access to your account."}
        keyword={"Shivaanzarionline, forgot password, reset password, recover account, account security, password recovery, secure login, online shopping"}
        author={"Shivaanzarionline Team"}
        >

            <div className="container-fluid py-5 bg-light">
                <div className="row justify-content-center align-items-center mx-1">
                    <div className="col-12 col-sm-8 col-md-6 col-lg-5 shadow-lg p-4 border border-primary rounded my-4 bg-white">
                        <h1 className="text-center mb-4 text-primary heading_font">Reset Password</h1>
                        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                            {/* Email Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <MdEmail />
                                </span>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control"
                                    placeholder="Email"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter a valid email.
                                </div>
                            </div>
                            {/* answer */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <MdOutlineSecurity />
                                </span>
                                <input
                                    type="text"
                                    value={answer}
                                    onChange={(e) => setAnswer(e.target.value)}
                                    className="form-control"
                                    placeholder="What is the name of the town where you were born ?"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter a valid email.
                                </div>
                            </div>
                            {/* Reset Password Field */}
                            <div className="mb-3 input-group">
                                <span className="input-group-text bg-primary text-white">
                                    <RiLockPasswordFill />
                                </span>
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="form-control"
                                    placeholder="Reset Password"
                                    required
                                />
                                <div className="invalid-feedback">
                                    Please enter your password.
                                </div>
                            </div>
                            {/* login Button */}

                            <button type="submit" className="btn btn-primary w-100 btn-lg">
                                Reset
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ForgotPassword
