import React, { useState } from "react";
import Layout from "./../components/Layout/Layout";
import { useCart } from "../context/cart";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const CartPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false); // State to manage QR modal visibility
  //   const [paymentConfirmed, setPaymentConfirmed] = useState(false); // State to track payment confirmation

  // upi id
  const [upiId, setUpiId] = useState("");

  // Calculate total price
  const totalPrice = () => {
    let total = 0;
    cart?.forEach((item) => {
      total += item.price;
    });
    return total.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    });
  };

  // Remove cart item
  const removeCartItem = (pid) => {
    let myCart = [...cart];
    let index = myCart.findIndex((item) => item._id === pid);
    myCart.splice(index, 1);
    setCart(myCart);
    localStorage.setItem("cart", JSON.stringify(myCart));
  };

  // Handle order placement
  const handlePlaceOrder = async () => {
    setLoading(true);
    try {
      // Show the QR code modal
      setShowQRModal(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Handle payment confirmation (e.g., after scanning QR code)
  const handlePaymentConfirmation = async () => {
    setLoading(true);  // Show loading spinner or feedback
    try {
      // Make the API call to confirm payment
      const { data } = await axios.post("/api/v1/product/payment", {
        cart,  // Send cart details to the backend
        upi_id: upiId, // Send UPI ID to backend
      });

      // Payment successful: handle UI updates and cleanup
      setLoading(false);
      localStorage.removeItem("cart");  // Clear cart from local storage
      setCart([]);  // Clear cart state
      navigate("/dashboard/user/orders");  // Redirect to orders page
      toast.success("Payment Completed Successfully.");  // Show success message
      setShowQRModal(false);  // Hide the QR modal
    } catch (error) {
      console.log(error);

      // Handle payment failure: UI updates and error message
      setLoading(false);
      toast.error("Payment Failed. Please try again.");  // Show error message
      setShowQRModal(false);  // Optionally hide the QR modal in case of failure
    }
  };


  // upi Id
  const handleUpiIdChange = (e) => {
    setUpiId(e.target.value);
  };

  return (
    <Layout 
    title={"Shopping Cart - Shivaanzarionline | Review Your Selected Items"}
    description={"View and manage the items in your shopping cart at Shivaanzarionline. Check product details, update quantities, and proceed to secure checkout for fast delivery of premium home essentials."}
    keyword={"Shivaanzarionline cart, shopping cart, view cart, update cart, checkout, home essentials, online shopping, secure checkout, premium products, fast delivery"}
    author={"Shivaanzarionline Team"}
    >
      <div className="container p-3">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center p-2 mb-1">{`Hello ${auth?.token && auth?.user?.name}`}</h1>
            <h4 className="text-center">
              {cart?.length
                ? `You Have ${cart.length} items in your cart`
                : "Your Cart Is Empty"}
            </h4>
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-lg-8 col-md-7 col-sm-12">
            {cart?.map((p) => (
              <div className="row mb-2 p-3 card flex-row" key={p._id}>
                <div className="col-4 col-sm-3 col-md-4">
                  <img
                    src={`/api/v1/product/product-photo/${p._id}`}
                    className="img-fluid card-img-top"
                    alt={p.name}
                  />
                </div>
                <div className="col-8 col-sm-9 col-md-8">
                  <h4>{p.name}</h4>
                  <p>{p.description}</p>
                  <h5 className="text-success pb-2" >
                    {p.price.toLocaleString("en-IN", {
                      style: "currency",
                      currency: "INR",
                    })}
                  </h5>
                  <button
                    className="btn btn-danger btn-sm"
                    onClick={() => removeCartItem(p._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12 text-center">
            <h2>Cart Summary</h2>
            <p>Total | Checkout | Payment</p>
            <hr />
            <h4>Total: {totalPrice()}</h4>

            {/* Address Section */}
            {auth?.user?.address ? (
              <div className="mb-3">
                <h4>Current Address</h4>
                <h5>{auth?.user?.address}</h5>
                <button
                  className="btn btn-outline-warning btn-sm bg-light"
                  onClick={() => navigate("/dashboard/user/profile")}
                >
                  Update Address
                </button>
              </div>
            ) : (
              <div className="mb-3">
                {auth?.token ? (
                  <button
                    className="btn btn-outline-warning btn-sm bg-light"
                    onClick={() => navigate("/dashboard/user/profile")}
                  >
                    Update Address
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-warning btn-sm bg-light"
                    onClick={() => navigate("/login", { state: "/cart" })}
                  >
                    Please Login to Checkout
                  </button>
                )}
              </div>
            )}

            {/* Payment Button */}
            <div className="mt-2">
              {cart?.length && auth?.user?.address ? (
                <button
                  className="btn btn-primary mt-2"
                  onClick={handlePlaceOrder}
                  disabled={loading}
                >
                  {loading ? "Processing ...." : "Place Order"}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* QR Code Modal */}
        {showQRModal && (
          <div
            className="modal fade show"
            tabIndex="-1"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Scan QR Code to Complete Payment</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowQRModal(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body text-center">
                  <img
                    src="/images/QR_Code.jpg"
                    alt="QR Code"
                    className="img-fluid"
                    style={{ width: "50%" }}
                  />
                  <h4>Total: {totalPrice()}</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="upiId"
                      placeholder="Enter your UPI ID"
                      onChange={handleUpiIdChange}
                      required
                    />
                  </div>
                  <p>Please scan the QR code with your payment app to complete the transaction.</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handlePaymentConfirmation}
                    disabled={!upiId}
                  >
                    Confirm Payment
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowQRModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default CartPage;
