import React from 'react'
import Layout from '../components/Layout/Layout'

const About = () => {
  return (
    <Layout
      title={"About Us - Shivaanzarionline | Learn More About Our Store"}
      description={"Learn more about Shivaanzarionline, our mission, values, and the team behind our store. Discover our commitment to providing premium home essentials at affordable prices."}
      keyword={"Shivaanzarionline, about us, our story, home essentials, online store, company values, mission, team"}
      author={"Shivaanzarionline Team"}
    >
     <div className="container-fluid py-3">
  <div className="row">
    <div className="col-md-6 col-sm-12 mb-md-0 mt-4">
      <img
        src="/images/about.jpeg"
        alt="About Us"
        className="img-fluid rounded"
        style={{
          objectFit: "cover",
          height: "auto",
        }}
      />
    </div>
    <div className="col-md-6 col-sm-12 mt-4">
      <h2 className="text-center heading_font mb-3">About Us</h2>
      <p className="text-justify about-text">
        Welcome to <strong>SHIVAANZARI ONLINE</strong>, your one-stop destination for all your daily needs! Our mission is to make everyday shopping convenient, affordable, and accessible to everyone, right from the comfort of your home.
        <br /><br />
        At SHIVAANZARI ONLINE, we understand the importance of having the essentials at your fingertips. From groceries and household products to personal care and wellness items, we offer a wide range of high-quality products to meet your everyday needs. Our team works tirelessly to source the best products from trusted suppliers, ensuring that you get value for your money with every purchase.
        <br /><br />
        <strong>Why Shop with Us?</strong>
        <ul className="list-unstyled">
          <li><strong>Convenience:</strong> Enjoy a hassle-free shopping experience with easy navigation, fast delivery, and secure payment options.</li>
          <li><strong>Quality:</strong> We are committed to offering only the best, sourcing products from top brands and trusted suppliers.</li>
          <li><strong>Affordability:</strong> Our goal is to provide quality products at competitive prices, helping you save more.</li>
          <li><strong>Customer Care:</strong> Our dedicated support team is always here to assist you with any questions or concerns, ensuring a smooth shopping experience.</li>
        </ul>
        <br />
        Thank you for choosing SHIVAANZARI ONLINE – Shopping for your daily needs made simple!
      </p>
    </div>
  </div>
</div>

    </Layout>
  )
}

export default About
