import React from "react";
import Layout from "./../components/Layout/Layout";
import { BiMailSend, BiPhoneCall, BiSupport } from "react-icons/bi";
import { ImWhatsapp } from "react-icons/im";
const Contact = () => {
  return (
    <Layout 
    title={"Contact Us - Shivaanzarionline | Get in Touch"}
    description={"Contact Shivaanzarionline for any questions or support. Reach out through our contact form, email, or phone to get assistance with your orders or account."}
    keyword={"Shivaanzarionline, contact us, customer support, contact form, online shopping, get in touch, customer service"}
    author={"Shivaanzarionline Team"}
    >
      <div className="container-fluid ">
        <div className="row d-flex ">
          <div className="col-md-6 col-sm-12 py-4">
            <img
              src="/images/contact.jpg"
              alt="contactus"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
          <div className="col-md-6 col-sm-12 pt-4 mt-md-0">
            <h1 className="bg-dark p-2 text-white text-center heading_font rounded">CONTACT US</h1>
            <p className="text-justify mt-2">
              Any query and info about the product? Feel free to call anytime, we are available 24x7.
            </p>
            <p className="mt-3">
              <BiMailSend /> : shivaanzarionline@gmail.com
            </p>
            <p className="mt-3">
              <BiPhoneCall /> : +91 9372303779
            </p>
            <p className="mt-3">
              <ImWhatsapp  /> : +91 9372303779
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
