import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import { FaUser, FaPhone, FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../context/auth';
// import { token } from 'morgan';

const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const navigate = useNavigate();
    const [auth, setAuth] = useAuth();
    const location = useLocation();

    // Form function 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Fetch the infomation into mangodb database
            const res = await axios.post("/api/v1/auth/login", {
                email,
                password,
            });
            if (res && res.data.success) {
                toast.success(res.data && res.data.message);
                setAuth({
                    ...auth,
                    user: res.data.user,
                    token: res.data.token,
                })
                localStorage.setItem('auth', JSON.stringify(res.data));
                setTimeout(() => {
                    navigate(location.state || "/");
                }, 2000);
            }
            else {
                toast.error(res.data.message)
            }
        } catch (error) {
            console.log(error)
            toast.error('Something went wrong')
        }
    };

    return (
        <Layout 
        title={"Login - Shivaanzarionline | Access Your Account"}
        description={"Login to your Shivaanzarionline account to track orders, manage your profile, and enjoy a personalized shopping experience for premium home essentials at affordable prices."}
        keyword={"Shivaanzarionline login, account login, user login, home essentials, online shopping, track orders, manage account, personalized shopping, secure login"}
        author={"Shivaanzarionline Team"}
        >
           <div className="container-fluid py-5 body_bg">
    <div className="row justify-content-center align-items-center mx-1">
        <div className="col-12 col-sm-10 col-md-8 col-lg-6 shadow-lg p-4 border border-primary rounded my-4 bg-white">
            <h1 className="text-center mb-4 text-primary heading_font">Login</h1>
            <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                {/* Email Field */}
                <div className="mb-3 input-group">
                    <span className="input-group-text bg-primary text-white">
                        <MdEmail />
                    </span>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        placeholder="Email"
                        required
                    />
                    <div className="invalid-feedback">
                        Please enter a valid email.
                    </div>
                </div>
                {/* Password Field */}
                <div className="mb-3 input-group">
                    <span className="input-group-text bg-primary text-white">
                        <RiLockPasswordFill />
                    </span>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        placeholder="Password"
                        required
                    />
                    <div className="invalid-feedback">
                        Please enter your password.
                    </div>
                </div>
                {/* Submit Button */}
                <div className="mb-3">
                    <button
                        type="button" 
                        className="btn btn-secondary w-100 btn-lg"
                        onClick={() => { navigate('/forgot-password') }}
                    >
                        Forgot Password
                    </button>
                </div>
                <button type="submit" className="btn btn-primary w-100 btn-lg">
                    Login
                </button>
            </form>
        </div>
    </div>
</div>


        </Layout>
    )
}

export default Login
