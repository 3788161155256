import React from "react";
import Layout from "./../components/Layout/Layout";
import { useSearch } from "../context/search";
import { useNavigate } from "react-router-dom";
const Search = () => {
    const navigate = useNavigate();
    const [values, setValues] = useSearch();
    return (
        <Layout title={"Search results"}>
            <div className="container">
                <div className="py-3">
                    <h1 className="text-center">Search Resuts</h1>
                    {/* <h6>
                        {values?.results.length < 1
                            ? "No Products Found"
                            : `Found ${values?.results.length}`}
                    </h6> */}
                    <div className="d-flex flex-wrap mt-4">
                        {values?.results.map((p) => (
                            <div className="card m-2" style={{ width: "18rem" }}>
                                <img
                                    src={`/api/v1/product/product-photo/${p._id}`}
                                    className="card-img-top"
                                    alt={p.name}
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{p.name}</h5>
                                    <p className="card-text">
                                        {p.description.substring(0, 30)}...
                                    </p>
                                    <h5 className="card-text"> $ {p.price}</h5>
                                    <div className="mt-auto d-flex flex-wrap">
                                        <button
                                            className="btn btn-primary ms-2 mb-2  w-md-auto"
                                            onClick={() => navigate(`/product/${p.slug}`)}
                                        >More details
                                        </button>
                                        <button className="btn btn-secondary ms-2 mb-2  w-md-auto">ADD TO CART</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Search;